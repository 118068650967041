:root {
  --shadow2: rgb(69, 189, 137);
  --shadow: rgb(152, 152, 153);
  --primarygrey2: rgb(219, 217, 217);
  --primarygrey: rgb(31, 31, 31);
  --buttononclick: rgb(100, 100, 100);
  --text: Arial;
  --textcolor: rgb(255, 255, 255);
  --background: rgb(51, 51, 51);
  --header: rgb(77, 77, 99);
  --disable: rgb(63, 63, 63);
  --card: rgb(230, 230, 230);
  --cardGoals: rgb(220, 220, 220);
}
.test {
  margin-top: 100px;
}
.App {
  text-align: center;
}
.infoBody {
  background-color: white;

  padding: 10px;
}
.cardImg {
  max-width: 33%;
  max-height: 300px;
}
.cardImg2 {
  max-width: 50%;
  max-height: 300px;
}
.cardImg3 {
  max-width: 100%;
  max-height: 700px;
}
.master {
  color: black;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.graph {
  color: black;
}

.header {
  font-size: 30px;
}

.slider_title {
  display: block;
}

.header_bar {
  background-color: var(--header);
  height: 60px;
}
.header_tag {
  margin: 0px;

  color: white;
  font-size: 15px;
  border: none;
  background-color: var(--header);
  display: inline-block;
  text-decoration: none;
  padding: 19px;
  outline: none;
  text-align: left;
}
.bottomHighlight {
  border-bottom: 5px solid var(--shadow);
}
.sliders_box {
  margin: 20px;
  margin-right: 50px;
}
.header_button {
  margin: 10px;
  height: 25px;
  color: white;
  font-size: 15px;
  width: 250px;
  border: none;
  background-color: var(--header);
  display: inline-block;
  text-decoration: none;
  padding: 10px;
  outline: none;
  text-align: left;
}
.drawerBg {
}
.block {
  width: 200px;
  margin: auto;
  display: inline-block;
}
.card {
  border-style: none;
  border-radius: 1px;
  border-color: var(--shadow);
  background-color: var(--card);
  text-align: left;
  padding: 10px;

  box-shadow: 1px 1px 2px 2px var(--shadow);
}
.card:active {
  border-style: none;
  border-radius: 1px;
  border-color: var(--shadow);
  text-align: left;
  background-color: var(--card);
  padding: 10px;
  box-shadow: 1px 1px 1px 1px var(--shadow);
}
.blockTitle {
  margin: auto;
  display: inline-block;
}
.header_button:hover {
  background-color: rgb(41, 41, 53);
}

.header_tag:hover {
  background-color: rgb(41, 41, 53);
}
.header_button:active {
  background-color: rgb(16, 16, 20);

  border: none;
}
.hover {
  color: rgb(18, 62, 119);
  text-align: center;
  font-size: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  margin-top: 10px;

  margin-bottom: 10px;
  padding-left: 8px;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.unhover {
  color: Grey;
  text-align: center;

  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -5px;
  font-size: 20px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.25s linear;
  -moz-transition: all 0.25s linear;
  -o-transition: all 0.25s linear;
  transition: all 0.25s linear;
}
.insideCard {
  padding-left: 20px;
  padding-right: 20px;
}

.insideCard {
  padding-left: 20px;
  padding-right: 20px;
}

.fullpage {
  width: 100%;
}
.tailer {
  text-align: center;
  font-size: 10px;

  margin-left: auto;
  margin-right: auto;
}
.logo {
  height: 30px;
  margin: 4px;
}
.timebox {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 300px;
  display: grid;
  grid-template-columns: auto 50px;
  background-color: var(--card);
  text-align: left;
  padding: 10px;
}
.rentbuycard {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  width: 300px;
  text-align: left;
  padding: 10px;
}

.smallblock {
  width: 50px;
  margin: 0px;
  display: inline-block;
}
.timesegment {
  grid-column: 1;
  float: right;
}
.timebutton {
  grid-column: 2;
  grid-row: 1 / span 2;
  border-radius: 5px;
  outline: none;
}
.plus {
  font-size: 20px;
}

.timebutton:active {
  background-color: rgb(194, 192, 192);
}

.MuiTimeline-root {
  width: 100px;
  padding-left: 0px;
  margin-left: -30px;
}
.burger {
  height: 50px;
}
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 10px;
  top: 7px;
}
.bm-cross {
  background: #023455;
}
/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: white;
}
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(77, 77, 99);
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: rgb(77, 77, 99);
}

/* Wrapper for item list */
.bm-item-list {
  color: rgb(77, 77, 99);
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(77, 77, 99);
}

.goalsBox {
  margin-bottom: 10px;
  font-size: 30px;
  outline: 0;
  border-width: 0 0 2px;
  border-color: blue;
  background-color: var(--card);
  width: 231px;
}
.percentageAddition {
  display: flex;
  flex-wrap: wrap;
}
.percentageAdditionBox {
  margin-bottom: 10px;
  font-size: 30px;
  outline: 0;
  border-width: 0 0 2px 2px;
  border-color: blue;
  background-color: var(--card);
  width: 75px;
}
.goalsCard {
  border-radius: 3px;

  text-align: left;
  padding: 10px;
  margin: 20px;
}
.sideSelector {
  display: flex;
  float: left;
}

.makeAPlanButton {
  background-color: rgb(100, 100, 100);
  border: none;
  color: #ffffff;
  text-align: center;

  transition: all 0.5s;
  cursor: pointer;
}
.planButtonSize {
  border-radius: 4px;
  font-size: 20px;
  padding: 10px;
  width: 200px;
  margin: 5px;
}

.plusDebtSize {
  border-radius: 4px;
  font-size: 20px;
  padding: 5px;
  height: 30px;
  margin: 5px;
  width: 75px;
  font-size: 20px;
}

.makeAPlanButton div {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.makeAPlanButton div:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.makeAPlanButton:hover div {
  padding-right: 25px;
}
.makeAPlanButton:hover {
  background-color: rgb(29, 94, 3);
}

.makeAPlanButton:hover div:after {
  opacity: 1;
  right: 0;
}

.toHaveBody {
  transition: 0.5s;
  opacity: 0;
  height: 0;
  overflow: hidden;
}
.toHaveBodyActive {
  transition: 0.5s;
  opacity: 1;
  text-align: left;
}

.card-container {
  font-size: 20px;
  text-align: center;
  border-style: none;
  border-radius: 3px;
  border-color: var(--shadow);

  display: inline-block;
  margin: 10px 10px 0px 10px;
  position: relative;
  padding: 10px;
  height: 100px;
  width: 100px;
  vertical-align: top;
  background-color: var(--header);
  color: rgb(255, 255, 255);
  box-shadow: 1px 1px 1px 1px var(--shadow);
}
.card-container.last {
  background-color: rgb(36, 73, 32);
}

.selectionBody {
  margin-top: 0px;
}

.debtsGrid {
  display: grid;
  grid-template-columns: 7em 1fr;
}

.debtsBox {
  display: grid;
  width: 200px;
  grid-template-columns: 5px 75px 50px 20px 30px;
  grid-gap: 4px;
  font-size: 20px;

  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
}

.removeDebtsButton {
  font-size: 15px;
  float: left;
  height: 25px;
  margin: 0px;
  border-radius: 4px;
  padding: 0px;
}

.debtBoxDebt {
  height: 20px;
  background-color: transparent;
  border-width: 0px 0px 2px 0px;
  font-size: 20px;
  border-radius: 0px;
}

.debtBoxDebtPercent {
  height: 20px;
  background-color: transparent;
  border-width: 0px 0px 2px 0px;
  font-size: 20px;
  border-radius: 0px;
}

.houseBoxDebt {
  width: auto;
  font-size: 20px;
  max-width: 500px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
}
.houseBoxRentBuy {
  width: auto;
  font-size: 20px;
  max-width: 700px;

  border-style: none;
  border-radius: 3px;
  border-color: var(--shadow);
  background-color: var(--cardGoals);

  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  box-shadow: 2px 4px 4px 4px var(--shadow);
}
.houseBox {
  display: flex;
  flex-wrap: wrap;
}
.flexChild {
  margin: 10px;
}
.houseBoxGrid {
  display: grid;
  grid-template-columns: 1fr 1em;
}
.inputBoxWith {
  width: 175px;
}

.inputBoxesStandard {
  margin: 10px;
  height: 20px;
  background-color: transparent;
  border-width: 0px 0px 2px 0px;
  font-size: 20px;
  border-radius: 0px;
}
.parallaxClass {
  margin: 0px;
}
.calculatorsflex {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  grid-gap: 20px;
}
.investmentCalculatorFlex {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
}

.hiddenimg {
  width: 100%;
  opacity: 1;
}

.iconDesc {
  margin: -5px;
}
